import { useContext, useState, useEffect, useCallback } from "react"
import { MapContext } from "../ol/MapProvider"
import ol_vector_Source from "ol/source/Vector"
import ol_format_GeoJSON from "ol/format/GeoJSON"
import ol_layer_VectorImage from 'ol/layer/VectorImage';
import { useSelector, useDispatch } from "react-redux";
import {Fill, Stroke, Style} from 'ol/style';
import ol_interaction_Select from 'ol/interaction/Select';
import { 
    MUNICIPALITY_ATTR_TITLE
} from "../constants";

const selectStyle = new Style({
    stroke: new Stroke({
        color: '#00F',
        width: 1
    }),
    zIndex: 1000,
    fill: new Fill({
        color: '#00F'
    }),
})   

const defaultStyle = new Style({
    fill: new Fill({
        color: '#fff'
    }),
    stroke: new Stroke({
      color: '#777777',
      width: 1
    }),
  });

const generateCategorizedStyle = (type, value) => {
    
    const getColor = () => {
        if(value === undefined || value === null) return "#fff"

        const item = type.find(x => value === x.value)
        return item ? item.color : "#777";
    }

    return new Style({
        fill: new Fill({
          color: getColor(value)
        }),
        stroke: new Stroke({
          color: "#777777",
          width: 1
        }),
      });
}

const generateGradientStyle = (type, value, filter) => {
    
    const getColor = () => {
        if(value === undefined || value === null) return "#fff"
        if(value >= filter[1] || value <= filter[0]) return "#fff"

        const item = type.find(x => value <= x.value)
        return item ? item.color : "#fff";
    }

    return new Style({
        fill: new Fill({
          color: getColor(value)
        }),
        stroke: new Stroke({
          color: "#777777",
          width: 1
        }),
      });
}

const MunicipalitiesLayer = ({
    url
}) => {
    
    const map = useContext(MapContext)
    
    const { visible, search, gradients, rangeFilter } = useSelector(state => state.municipalityLayer)
    const dispatch = useDispatch()

    const getGradientStyle = useCallback((feature) => {
        const activeGradient = gradients.find(g => g.active);
        if(activeGradient && activeGradient.type === "categorized")
            return generateCategorizedStyle(activeGradient.style, feature.get(activeGradient.attr))
        if(activeGradient)
            return generateGradientStyle(activeGradient.style, feature.get(activeGradient.attr), rangeFilter)
        return defaultStyle;
    }, [gradients, rangeFilter])

    const setGradientStyle = useCallback((feature) => {
        const s = getGradientStyle(feature)
        feature.setStyle(s)
    }, [getGradientStyle])
    
    const l = new ol_layer_VectorImage({
        imageRatio: 2,
        visible,
        source: new ol_vector_Source({
            url,
            format: new ol_format_GeoJSON()
        }),
        style: setGradientStyle
    })
     
    const [olLayer] = useState(l)
    

    useEffect(() => {
        
        const features = olLayer.getSource().getFeatures();
        const results = []

        const adaptString = (str) => {
            return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        }

        let count = 0;

        features.forEach((feature) => {
            if(count > 8) return;
            if(adaptString(feature.get(MUNICIPALITY_ATTR_TITLE)).startsWith(search)){
                results.push(feature.clone())
                count++
            }
        })

        const setSearchResults = (r) => {
            dispatch({type: "SET_SEARCH_RESULTS", results: r})
        }

        setSearchResults(results)

    }, [search, olLayer, dispatch])

    
    useEffect(() => {
        const features = olLayer.getSource().getFeatures();
        features.forEach(setGradientStyle)
    }, [olLayer, setGradientStyle])
    

    useEffect(() => {

        const callIdentification = (feature) => {
            dispatch({type: "SET_IDENTIFICATION", feature, open: true})
        }

        const selectClick = new ol_interaction_Select({layers: [olLayer], style: selectStyle});
       
        map.addInteraction(selectClick);

        selectClick.on('select', function(e) {
            if(!e.selected.length) return
            const selected = e.selected[0]
            callIdentification(selected)
        })

        return () => {
            map.removeInteraction(selectClick);
        }

    }, [map, dispatch, olLayer])


    useEffect(() => {
        olLayer && olLayer.setVisible(visible)
    }, [olLayer, visible])


    useEffect(() => {
        map.addLayer(olLayer)
        return () => {
            map.removeLayer(olLayer)
        }
    }, [map, olLayer])
    
    return null
}

export default MunicipalitiesLayer