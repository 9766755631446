export const MUNICIPALITY_ATTR_TITLE = "nm4";

export const GRADIENT_SAS = [{
    value: 5,
    color: "#ccffbd"
},{
    value: 10,
    color: "#9af280"
},{
    value: 15,
    color: "#63d442"
},{
    value: 20,
    color: "#2f9611"
},{
    value: 100,
    color: "#1d7504"
}]

export const GRADIENT_SDKU = [{
    value: 1,
    color: "#c9daff"
},{
    value: 2,
    color: "#99b9ff"
},{
    value: 5,
    color: "#6896fc"
},{
    value: 10,
    color: "#3c77fa"
},{
    value: 100,
    color: "#0a46c9"
}]

export const GRADIENT_BLUE = [{
    value: 5,
    color: "#c9daff"
},{
    value: 10,
    color: "#99b9ff"
},{
    value: 15,
    color: "#6896fc"
},{
    value: 20,
    color: "#3c77fa"
},{
    value: 100,
    color: "#0a46c9"
}]

export const GRADIENT_RED = [{
    value: 5,
    color: "#ffd7d4"
},{
    value: 10,
    color: "#faa49d"
},{
    value: 15,
    color: "#fa7266"
},{
    value: 20,
    color: "#d93325"
},{
    value: 100,
    color: "#94150a"
}]

export const GRADIENT_SMER = [{
    value: 10,
    color: "#ffd7d4"
},{
    value: 15,
    color: "#faa49d"
},{
    value: 20,
    color: "#fa7266"
},{
    value: 30,
    color: "#d93325"
},{
    value: 100,
    color: "#94150a"
}]

export const GRADIENT_DARKGREEN = [{
    value: 10,
    color: "#aaf2b6"
},{
    value: 15,
    color: "#65bf74"
},{
    value: 20,
    color: "#2bb542"
},{
    value: 30,
    color: "#0d8521"
},{
    value: 100,
    color: "#12521d"
}]


export const GRADIENT_LSNS = [{
    value: 5,
    color: "#e6bb81"
},{
    value: 10,
    color: "#e6a957"
},{
    value: 15,
    color: "#bd8233"
},{
    value: 20,
    color: "#a36818"
},{
    value: 100,
    color: "#6e4610"
}]

export const CATEGORIZED_RESULTS = [{
    value: "olano",
    title: "OĽANO",
    color: "#1e943d"
},{
    value: "smer",
    title: "SMER-SD",
    color: "#EE5757"
},{
    value: "sas",
    title: "SaS",
    color: "#AFD14A"
},
// {
//     value: "ps",
//     title: "PS/SPOLU",
//     color: "#4D78B4"
// },
// {
//     value: "zaludi",
//     title: "ZA ĽUDÍ",
//     color: "#F08A25"
// },
{
    value: "rodina",
    title: "SME RODINA",
    color: "#5D5D9A"
},{
    value: "lsns",
    title: "ĽSNS",
    color: "#004800"
},{
    value: "kdh",
    title: "KDH",
    color: "#0076BA"
},{
    value: "smk",
    title: "MKO-MKS",
    color: "#fcc419"
},{
    value: "most",
    title: "MOST - HÍD",
    color: "#e8f257"
},
// {
//     value: "dobravolba",
//     title: "DOBRÁ VOĽBA",
//     color: "#FF002B"
// },
{
    value: "sns",
    title: "SNS",
    color: "#243780"
},
// {
//     value: "vlast",
//     title: "VLASŤ",
//     color: "#154B6C"
// },
{
    value: "siet",
    title: "SIEŤ",
    color: "#3edff7"
},
{
    value: "x",
    title: "Viac víťazov",
    color: "#777"
}
]