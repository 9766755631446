import React, {Fragment} from 'react';
import {withStyles, Drawer, IconButton, Divider, useTheme, useMediaQuery} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { useSelector, useDispatch } from 'react-redux';

const styles = (theme) => ({
   root: {

   },
   logo: {
        height: 50,
        width: "auto"
   },
   drawerHeader: {
        position: "relative",
        display: "flex",
        padding: 8,
        height: 60,
        alignItems: "center",
   },
   button: {
       position: "absolute",
       right: 0,
   }
})


const SideNav = ({
    classes,
    children
}) => {

    const open = useSelector(state => state.general.showMenu)
    const dispatch = useDispatch();
    const theme = useTheme()
    const match = useMediaQuery(theme.breakpoints.down('sm'))

    const toggleDrawer = () => {
        dispatch({type: 'SHOW_MENU', payload: !open})
    }

    return (
        <Fragment>
            <Drawer
                variant={match ? "temporary" : "persistent"}
                open={open}
                onClose={toggleDrawer}
            >
                <div className={classes.drawerHeader}>
                    <img src="/img/goodmapLogo.png" className={classes.logo} alt="logo"/>
                    <IconButton className={classes.button} onClick={toggleDrawer}>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                {children}
            </Drawer>
        </Fragment>
    )
}


SideNav.propTypes = {
   
}

SideNav.defaultProps = {
   
}

export default withStyles(styles)( SideNav )