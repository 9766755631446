import { useSelector, useDispatch } from "react-redux"
import List from '@material-ui/core/List';
import LayerIcon from '@material-ui/icons/LayersOutlined';
import React from 'react'
import { Typography, makeStyles } from "@material-ui/core";
import LayerItem from "../components/LayerSwitcher/LayerItem";

const useStyles = makeStyles((theme) => ({
    
    title: {
        paddingLeft: 16,
        color: theme.palette.grey[400]
    }
}))

const MunicipalitiesLayerSwitcher = () => {

    const { gradients } = useSelector(state => state.municipalityLayer)
    const layers = useSelector(state => state.map.layers)
    const dispatch = useDispatch()
    const classes = useStyles()

    const setRangeFilter = (range) => {
        dispatch({type: "SET_RANGE_FILTER", range})
    }

    const setLayerVisibility = (id, visible) => {
        dispatch({type: "SET_LAYER_VISIBILITY", id, visible})
    }

    const setActiveGradietLayer = (id) => {
        dispatch({type: "SET_ACTIVE_GRADIENT", id})
    }

    return (
        <>  
        {layers.length ?
            <List className={classes.root}>
                <Typography gutterBottom className={classes.title}>Podkladové vrstvy</Typography>
                {
                    layers.map((layer) => (      
                        <LayerItem
                            key={layer.id}
                            icon={<LayerIcon/>}
                            id={layer.id}
                            title={layer.title}
                            onChange={(id) => setLayerVisibility(id, !layer.visible)}
                            active={layer.active}
                        />                                                            
                    ))
                }
            </List> : null
            }
            <List>
            <Typography gutterBottom className={classes.title}>Politické strany</Typography>
            {
                gradients.map((gradient) => (
                    <LayerItem
                        icon={<LayerIcon/>}
                        id={gradient.id}
                        title={gradient.title}
                        legendType={gradient.type === "categorized" ? "vertical" : "horizontal"}
                        setRangeFilter={setRangeFilter}
                        onChange={setActiveGradietLayer}
                        active={gradient.active}
                        legendItems={gradient.style}
                    />
                ))
            }
            </List>
        </>
    )
}

export default MunicipalitiesLayerSwitcher