import { useContext, useState, useEffect } from "react"
import { MapContext } from "../MapProvider"
import ol_vector_Source from "ol/source/Vector"
import ol_format_GeoJSON from "ol/format/GeoJSON"
import ol_layer_VectorImage from 'ol/layer/VectorImage';
import {Stroke, Style} from 'ol/style';

const GeoJSONLayer = ({
    url,
    visible,
    strokeWidth = 1
}) => {
    
    const style = new Style({
        stroke: new Stroke({
            color: '#555',
            width: strokeWidth
        }),
    })   

    const map = useContext(MapContext)

    const l = new ol_layer_VectorImage({
        imageRatio: 2,
        visible,
        source: new ol_vector_Source({
            url,
            format: new ol_format_GeoJSON()
        }),
        style: (feature) => {
            return style
        }
    })
    
     
    const [olLayer] = useState(l)


    useEffect(() => {
        olLayer && olLayer.setVisible(visible)
    }, [olLayer, visible])

    useEffect(() => {
        map.addLayer(olLayer)
        return () => {
            map.removeLayer(olLayer)
        }
    }, [map, olLayer])
    
    return null
}

export default GeoJSONLayer