import { 
    GRADIENT_BLUE,
    GRADIENT_RED,
    GRADIENT_SMER,
    GRADIENT_SAS,
    GRADIENT_SDKU,
    GRADIENT_LSNS,
    GRADIENT_DARKGREEN,
    CATEGORIZED_RESULTS
} from "../constants"

const initState = {
    visible: true,
    search: "",
    searchResults: [],
    mode: "gradient",
    rangeFilter: [0,100],
    identification: {
        open: false,
        title: "",
        attributes: [{
            id: "smer",
            title: "SMER-SD",
            value: null,
            color: "#ff0000",
        }, {
            id: "sas",
            title: "SaS",
            value: null,
            color: "#96C72D",
        },{
            id: "olano",
            title: "OĽANO",
            value: null,
            color: "#C6D20",
        },{
            id: "sns",
            title: "SNS",
            value: null,
            color: "#69a2ff",
        },{
            id: "rodina",
            title: "SME RODINA",
            value: null,
            color: "#bc42f5",
        },{
            id: "kdh",
            title: "KDH",
            value: null,
            color: "#a2b0e0",
        },{
            id: "lsns",
            title: "ĽSNS",
            value: null,
            color: "#437335",
        },{
            id: "most",
            title: "Most Híd",
            value: null,
            color: "#d1c73f",
        },{
            id: "siet",
            title: "SIEŤ",
            value: null,
            color: "#a19fe0",
        },{
            id: "kss",
            title: "KSS",
            value: null,
            color: "#48d9d1",
        },{
            id: "smk",
            title: "SMK",
            value: null,
            color: "#9dbdb5",
        },{
            id: "vzdor",
            title: "VZDOR",
            value: null,
            color: "#48d9d1",
        },{
            id: "skok",
            title: "SKOK",
            value: null,
            color: "#b5ffbb",
        },{
            id: "tip",
            title: "TIP",
            value: null,
            color: "#c4fffa",
        },{
            id: "pd",
            title: "PD",
            value: null,
            color: "#48d9d1",
        },{
            id: "sanca",
            title: "ŠANCA",
            value: null,
            color: "#ffadf4",
        },{
            id: "sms",
            title: "SMS",
            value: null,
            color: "#d5a1ff",
        },{
            id: "ds",
            title: "DS",
            value: null,
            color: "#48d9d1",
        },{
            id: "spolocne",
            title: "SPOLOČNE",
            value: null,
            color: "#48d9d1",
        },{
            id: "mkda",
            title: "MKDA",
            value: null,
            color: "#d5a1ff",
        },{
            id: "odvaha",
            title: "ODVAHA",
            value: null,
            color: "#ffadf4",
        }]
    },
    gradients: [
        {
            id: "results",
            title: "Vítazné politické strany",
            active: true,
            attr: "won",
            type: "categorized",
            style: CATEGORIZED_RESULTS
        },
        {
            id: "sas",
            title: "SaS",
            active: false,
            attr: "sas",
            style: GRADIENT_SAS
        },
        {
            id: "sdku",
            title: "SDKÚ",
            active: false,
            attr: "sdku",
            style: GRADIENT_SDKU
        },
        {
            id: "smer",
            title: "SMER-SD",
            active: false,
            attr: "smer",
            style: GRADIENT_SMER
        },
        {
            id: "lsns",
            title: "ĽSNS",
            active: false,
            attr: "lsns",
            style: GRADIENT_LSNS
        },
        {
            id: "rodina",
            title: "SME RODINA",
            active: false,
            attr: "rodina",
            style: GRADIENT_RED
        },
        {
            id: "olano",
            title: "OĽANO",
            active: false,
            attr: "olano",
            style: GRADIENT_DARKGREEN
        },
        {
            id: "sns",
            title: "SNS",
            active: false,
            attr: "sns",
            style: GRADIENT_RED
        },
        {
            id: "most",
            title: "MOST HÍD",
            active: false,
            attr: "most",
            style: GRADIENT_RED
        },
        {
            id: "siet",
            title: "SIEŤ",
            active: false,
            attr: "siet",
            style: GRADIENT_RED
        },
        {
            id: "kdh",
            title: "KDH",
            active: false,
            attr: "kdh",
            style: GRADIENT_BLUE
        },
        {
            id: "smk",
            title: "SMK",
            active: false,
            attr: "smk",
            style: GRADIENT_BLUE
        },
        // {
        //     id: "tip",
        //     title: "TIP",
        //     active: false,
        //     attr: "tip",
        //     style: GRADIENT_BLUE
        // },
        // {
        //     id: "kss",
        //     title: "KSS",
        //     active: false,
        //     attr: "kss",
        //     style: GRADIENT_BLUE
        // },
        // {
        //     id: "sms",
        //     title: "SMS",
        //     active: false,
        //     attr: "sms",
        //     style: GRADIENT_BLUE
        // },
        // {
        //     id: "ds",
        //     title: "DS",
        //     active: false,
        //     attr: "ds",
        //     style: GRADIENT_BLUE
        // },
        // {
        //     id: "sanca",
        //     title: "ŠANCA",
        //     active: false,
        //     attr: "sanca",
        //     style: GRADIENT_BLUE
        // },
        // {
        //     id: "szs",
        //     title: "SZS",
        //     active: false,
        //     attr: "szs",
        //     style: GRADIENT_BLUE
        // },
        // {
        //     id: "spolocne",
        //     title: "Spoločne",
        //     active: false,
        //     attr: "spolocne",
        //     style: GRADIENT_BLUE
        // },
        // {
        //     id: "mkda",
        //     title: "MKDA",
        //     active: false,
        //     attr: "mkda",
        //     style: GRADIENT_BLUE
        // },
        // {
        //     id: "vzdor",
        //     title: "VZDOR",
        //     active: false,
        //     attr: "vzdor",
        //     style: GRADIENT_BLUE
        // },
        // {
        //     id: "odvaha",
        //     title: "ODVAHA",
        //     active: false,
        //     attr: "odvaha",
        //     style: GRADIENT_BLUE
        // },
        // {
        //     id: "skok",
        //     title: "SKOK",
        //     active: false,
        //     attr: "skok",
        //     style: GRADIENT_BLUE
        // },
        
        // {
        //     id: "pd",
        //     title: "PD",
        //     active: false,
        //     attr: "pd",
        //     style: GRADIENT_BLUE
        // }
    ],
    filter: [
        
    ]
    
}

const adaptString = (str) => {
    return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

const municipalityLayer = (state = initState, action) => {
    switch (action.type) {
        case 'SEARCH':
            return {
                ...state,
                search: adaptString(action.search)
            }
        case 'SET_SEARCH_RESULTS':
            return {
                ...state,
                searchResults: action.results
            }
        case 'SET_RANGE_FILTER':
            return {
                ...state,
                rangeFilter: action.range
            }
        case 'SET_MUNICIPALITY_VISIBLE':
            return {
                ...state,
                visible: action.visible
            }
        case 'CLOSE_IDENTIFICATION_DIALOG':
            return {
                ...state,
                identification: { ...state.identification, open: false}
            }
        case 'SET_IDENTIFICATION':
            let identification = state.identification;
            const attr = state.identification.attributes.map(f => {
                const entry = Object.entries(action.feature.getProperties()).find(([key]) => key === f.id);
                return {
                    ...f,
                    value: entry.length > 1 ? entry[1] : null
                }
            })
            identification.attributes = attr;
            return {
                ...state,
                identification: { ...identification, title: action.feature.get("nm4"), open: true }
            }
        case 'SET_ACTIVE_GRADIENT': 
            const gradients = state.gradients.map(g => ({
                ...g,
                active: action.id === g.id ? !g.active : false
            }))
            
            const isAnyActiveGradient = gradients.find(g => g.active === true);

            return {
                ...state,
                gradients,
                rangeFilter: [0,100],
                mode: isAnyActiveGradient ? "gradient" : "filter",
                filter: state.filter.map(f => ({
                    ...f,
                    active: false
                })),
            }
        case 'ACTIVE_MUNICIPALITY_FILTER':
            return {
                ...state,
                filter: state.filter.map(f => ({
                    ...f,
                    active: f.id === action.id ? action.active : f.active
                })),
                mode: "filter",
                gradients: state.gradients.map(g => ({...g, active: false}))
            }
        default:
            return state
    }
}

export default municipalityLayer;